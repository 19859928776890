<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll">
		<BHLoading :show="loading" />
		<h4 class="mb-3"> Settings</h4>
		<SettingsTab @menuClick="onTabChange" :menuList="menuList" :activeTab="activeTab" width="280">
			<Address v-if="activeTab === 'address'" />
			<Reservation v-else-if="activeTab === 'reservation'" />
			<DefaultSettings v-else-if="activeTab === 'defaultSettings'" />
			<CustomFields v-else-if="activeTab === 'customFields'" />
			<Premiums v-else-if="activeTab === 'premiums'" />
			<AddOns v-else-if="activeTab === 'addons'" />
			<ManageData v-else-if="activeTab === 'manageData'" />
		</SettingsTab>
	</div>
</template>

<script>
import SettingsTab from 'bh-mod/components/common/SettingsTab'
import BHLoading from 'bh-mod/components/common/Loading'
import Address from "./address"
import Reservation from "./reservation"
import DefaultSettings from "./defaultSettings"
import CustomFields from "./custom-fields"
import Premiums from "./premiums"
import AddOns from "./addons"
import ManageData from "./manageData"

export default {
	components: {
		BHLoading,
		Address,
		Reservation,
		DefaultSettings,
		CustomFields,
		Premiums,
		AddOns,
		ManageData,
		SettingsTab
	},
	data() {
		return {
			loading: false,
			activeTab: 'address',
		}
	},

	computed: {
		instance() {
			return this.$store.state.instance
		},

		menuList() {
			const items = [
				{
					label: 'General Settings', id: 'sub1', children: [
						{ label: 'Condo Address', id: 'address' },
						{ label: 'Reservation', id: 'reservation' },
					]
				},
				{
					label: 'Customization', id: 'sub2', children: [
						{ label: 'Official Information Default Settings', id: 'defaultSettings' },
						{ label: 'Custom Fields', id: 'customFields' },
						{ label: 'Premiums', id: 'premiums' },
						{ label: 'Add Ons', id: 'addons' }
					],
					info: 'Warning: If you delete any customizations, you will lose all the data associated with it inside your units'
				},
				{
					label: 'Data Management', id: 'sub3', children: [
						{ label: 'Manage Data', id: 'manageData' },
					]
				},
			]
			if (!this.$store.getters.isReservationActive) {
				items[0].children.splice(1, 1)
			}
			return items;
		},
	},

	created() {
		this.$store.commit('SHOW_FLOOR', 'settings')
		this.$store.commit('SET_CRUMBS', [{ name: 'Settings' }])

        this.$api.get(`/settings/:instance/condounits`).then(({ data }) => {
            if (data) {
                this.$store.commit("UPDATE_SETTINGS", data);
            }
        }).catch(err => {
			console.log('Error occurred while condounits settings.', err);
		});
	},

	methods: {
		onTabChange(e) {
			this.activeTab = e
		},
	},
}
</script>
