<template>
	<div>
		<a-table :rowKey="(e) => e.id" :columns="columns" class="white-table" :data-source="data">
            <template v-slot:price="addOn">
                ${{ $formatNumber(addOn.price) }}
            </template>
			<template v-slot:status="addOn">
				{{ statusObj[addOn.status] || addOn.status }}
			</template>
			<template v-slot:unitId="addOn">
				{{ unitName(addOn) }}
			</template>
			<template v-slot:tags="addOn">
				<a-tag v-for="(tag, index) in addOn.tags" :key="index">{{ tag }}</a-tag>
			</template>
			<template v-slot:action="addOn">
				<div class="dF aC jSB" style="gap: 10px;">
					<img src="@/assets/copy.svg" alt="copy" @click="$emit('copy', addOn, type)"
						style="cursor: pointer" />
					<img v-if="addOn.status === 'available' || !addOn.status" src="@/assets/edit.svg" alt="edit"
						@click="$emit('edit', addOn, type)" style="cursor: pointer" />
					<img v-if="addOn.status === 'available' || !addOn.status" src="@/assets/delete.svg" alt="delete"
						@click="$emit('delete', addOn, type)" style="cursor: pointer" />
				</div>
			</template>
		</a-table>
		<div @click="$emit('add', type)" :class="['addButton ml-3', { 'mt-5': !data.length }]">
			<div class="dF aC">
				<a-icon style="font-size: 20px" class="mr-3" type="plus-circle" />
				<div>{{ addText }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from "vuex";

	export default {
		props: {
			columns: Array,
			data: Array,
			type: String,
			addText: String,
		},
		data() {
			return {
				statusObj: {
					available: "Available",
					sold: "Sold",
					hold: "Hold",
					conditional: "Sold Conditional",
					inventory: "Standing Inventory",
					notreleased: "Not Released",
					allocated: "Allocated",
					approved_for_reservation: "Approved",
					reserved: "Reserved"
				}
			};
		},
		computed: {
			...mapState({
				units: (state) => Object.values(state.condoUnits.units),
			}),
		},
		methods: {
			unitName(obj) {
				let unit = this.units.find((x) => x.id == obj.unitId);
				return unit ? `${unit.unitNumber} ${unit.name ? ' - ' + unit.name : ''} (${this.statusObj[unit.salesStatus] || unit.salesStatus}) ${unit.status == 'draft' ? '(Draft)' : ''}` : "";
			},
		},
	};
</script>

<style scoped>
	.addButton {
		cursor: pointer;
		color: var(--orange);
		display: inline-block;
	}
</style>
