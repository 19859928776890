<template>
    <div class="dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
        <a-card title="Official Information Default Settings">
            <a-form-model class="mt-3" ref="newSetup">
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-model-item prop="closingDate">
							<div slot="label" class="dF aC">
								<div>Closing Date</div>
								<!-- <a-checkbox class="ml-4" v-model="termination.notApplicable">Not applicable</a-checkbox> -->
							</div>
							<a-date-picker style="width:100%" v-model="closing.closingDate" placeholder="YYYY-MM-DD"></a-date-picker>
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item prop="outsideClosing">
							<div slot="label" class="dF aC">
								<div>Outside Closing Date</div>
								<!-- <a-checkbox class="ml-4" v-model="termination.notApplicable">Not applicable</a-checkbox> -->
							</div>
							<a-date-picker style="width:100%" v-model="outside.outsideClosing" placeholder="YYYY-MM-DD"></a-date-picker>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-model-item prop="terminationDate">
							<template v-if="termination.dynamicTerminationDate">
								<div slot="label">Termination Date</div>
								<a-tooltip
									overlayClassName="change-tooltip-color"
									style="
										position: absolute;
										top: -30px;
										left: 110px;
									"
								>
									<template slot="title">
										Termination date will be calculated based on the number of days, months, or years from the offer date.
									</template>
									<a-icon
										type="question-circle"
										style="
											line-height: 22.5px;
											margin-top: 5px;
											color: var(--orange);
										"
									/>
								</a-tooltip>
								<a-row :gutter="16">
									<a-col :span="8">
										<a-input-number
											v-model="termination.dynamicTerminationDateValue"
											style="width: 100%"
											:min="0"
											:max="366"
										></a-input-number>
									</a-col>
									<a-col :span="16">
										<a-select
											v-model="termination.dynamicTerminationDateUnit"
											style="width: 100%"
										>
											<a-select-option value="days">Days</a-select-option>
											<a-select-option value="months">Months</a-select-option>
											<a-select-option value="years">Years</a-select-option>
										</a-select>
									</a-col>
								</a-row>
							</template>
							<template v-else>
								<div slot="label" class="dF aC">
									<div>Termination Date</div>
									<!-- <a-checkbox class="ml-4" v-model="termination.notApplicable">Not applicable</a-checkbox> -->
								</div>
								<a-input v-if="false && termination.notApplicable" style="width:100%" value="N/A" :disabled="termination.notApplicable"></a-input>
								<a-date-picker v-else style="width:100%" v-model="termination.terminationDate" placeholder="YYYY-MM-DD"></a-date-picker>
							</template>
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="Dynamic Termination Date" prop="dynamicTerminationDate">
							<a-switch v-model="termination.dynamicTerminationDate" :disabled="termination.notApplicable"></a-switch>
						</a-form-model-item>
					</a-col>
				</a-row>
            </a-form-model>

            <a-button
                class="pull-right"
                size="large"
                type="primary"
                @click="save"
                :loading="loading"
                >SAVE</a-button
            >
        </a-card>
    </div>
</template>

<script>
export default {
    props: {},
    data: () => ({
        loading: false,
        closing: {
            closingDate: null,
        },
		outside: {
            outsideClosing: null,
        },
		termination: {
            terminationDate: null,
			dynamicTerminationDate: false,
			dynamicTerminationDateUnit: "days",
			dynamicTerminationDateValue: 0,
			// notApplicable: false
        },
    }),
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        closingDate() {
            if (
                this.$store.state.condoUnits.allSettings &&
                this.$store.state.condoUnits.allSettings.app &&
                this.$store.state.condoUnits.allSettings.app.options &&
                this.$store.state.condoUnits.allSettings.app.options.closing
            ) {
                return this.$store.state.condoUnits.allSettings.app.options
                    .closing;
            }
            return {
                closingDate: null,
            };
        },
		outsideClosing() {
            if (
                this.$store.state.condoUnits.allSettings &&
                this.$store.state.condoUnits.allSettings.app &&
                this.$store.state.condoUnits.allSettings.app.options &&
                this.$store.state.condoUnits.allSettings.app.options.outside
            ) {
                return this.$store.state.condoUnits.allSettings.app.options
                    .outside;
            }
            return {
                outsideClosing: null,
            };
        },
		terminationDate() {
            if (
                this.$store.state.condoUnits.allSettings &&
                this.$store.state.condoUnits.allSettings.app &&
                this.$store.state.condoUnits.allSettings.app.options &&
                this.$store.state.condoUnits.allSettings.app.options.termination
            ) {
                return this.$store.state.condoUnits.allSettings.app.options
                    .termination;
            }
            return {
                terminationDate: null,
				dynamicTerminationDate: false,
				dynamicTerminationDateUnit: "days",
				dynamicTerminationDateValue: 0,
				// notApplicable: false
            };
        },
    },
    created() {
        this.closing = this.closingDate;
        this.$api.get(`/settings/:instance/condounits`).then(({ data }) => {
            if (data) {
                this.$store.commit("UPDATE_SETTINGS", data);
                this.closing = this.closingDate;
				this.outside = this.outsideClosing;
				this.termination = this.terminationDate;
            }
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				console.log('Error occurred while condounits settings.', err);
			}
		});
    },

    methods: {
        save() {
            this.loading = true;
            this.$api
                .put(`/settings/:instance/condounits`, {
                    options: { 
						closing: this.closing,
						outside: this.outside,
						termination: this.termination
					},
                })
                .then(({data}) => {
                	this.$store.commit("UPDATE_SETTINGS", data);
                    this.$message.success(
                        "Official information default settings updated successfully."
                    );
                })
                .catch((err) => {
                    console.error("ERROR in adding default setting details", err);
                    this.$message.success(
                        "Error occurred while updating official information default settings. Please try again!"
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style>
</style>
