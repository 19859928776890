<template>
    <div class="dF fC f1 pb-4 hide-scrollbar" style="overflow-y:scroll">
        <SettingModal />
        <a-card title="Premiums">
            <a-table :rowKey="(e) => e.id" :columns="columns" class="white-table" :data-source="premiums">
                <!-- <div slot="default" slot-scope="obj">
                    <div class="dF" v-if="typeof obj.defaultAnswer == 'object'">
                        <div class="px-2" :class="ansI != obj.defaultAnswer.length-1 ? 'mr-3' : ''" style="background-color:var(--off-white-dark); border:1px solid #000" v-for="(ans, ansI) in obj.defaultAnswer" :key="ans+ansI">{{ans}}</div>
                    </div>
                    <div v-else>{{obj.defaultAnswer}}</div>
                </div> -->
                <div slot="date" slot-scope="obj" style="color:#9EA0A5">{{obj.date ? convertDate(obj.date) : ''}}</div>
                <div slot="user" slot-scope="obj">{{obj.user && obj.user.firstName && obj.user.lastName && obj.user.firstName!='' && obj.user.lastName!=''? `${obj.user.firstName} ${obj.user.lastName}`:''}}</div>
                <!-- <div slot="type" slot-scope="obj">{{obj.type == 'text' ? 'Text' : 'Multiple Choice'}}</div> -->
                <div slot="action" slot-scope="obj" class="dF">
                    <div @click="editPremium(obj)" class="mr-3" style="cursor:pointer"><img src="@/assets/edit.svg" alt="edit"></div>
                    <div v-if="obj.id != 'floorPremium' && obj.id != 'viewPremium' && obj.id != 'balconyPremium' && obj.id != 'terracePremium'" @click="deletePremium(obj)" style="cursor:pointer"><img src="@/assets/delete.svg" alt="delete"></div>
                </div>
            </a-table>
            <div @click="addPremium" style="padding-top:24px; cursor:pointer; color:var(--orange); display:inline-block">
                <div class="dF aC">
                    <a-icon style="font-size:20px" class="mr-3" type="plus-circle" />
                    <div>Add Unit Premium</div>
                </div>
            </div>
        </a-card>
    </div>
</template>

<script>
import SettingModal from '@/components/condounits/SettingModal'
export default {
    components:{SettingModal},
    computed:{
		dateFormat(){
			return this.$store.state.condoUnits.allSettings.user && this.$store.state.condoUnits.allSettings.user.options && this.$store.state.condoUnits.allSettings.user.options.regional && this.$store.state.condoUnits.allSettings.user.options.regional.dateFormat ? this.$store.state.condoUnits.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        premiums() {
            if (this.$store.state.condoUnits.allSettings && this.$store.state.condoUnits.allSettings.app && this.$store.state.condoUnits.allSettings.app.options && this.$store.state.condoUnits.allSettings.app.options.premiums && this.$store.state.condoUnits.allSettings.app.options.premiums.length) {
                return this.$store.state.condoUnits.allSettings.app.options.premiums
            }

			return [
                {
                    name:'Floor Premium',
                    value:'',
                    id:'floorPremium'
                },
                {
                    name:'View Premium',
                    value:'',
                    id:'viewPremium'
                },
                {
                    name:'Balcony Premium',
                    value:'',
                    id:'balconyPremium'
                },
                {
                    name:'Terrace Premium',
                    value:'',
                    id:'terracePremium'
                },
            ]
        },
    },
    data() {
        return{
            fieldDrawer:{
                visible:false,
                type:'add'
            },
            columns:[
                {
                    dataIndex:"name",
                    key:"name",
                    title:'Name'
                },
                {
                    dataIndex:"value",
                    key:"value",
                    title:'Value'
                },
                {
                    title:'Date Created',
                    key:'date',
                    slots:{title:'Date Created'},
                    scopedSlots:{customRender:'date'}
                },
                {
                    title:'Modified By',
                    key:'user',
                    slots:{title:'Modified By'},
                    scopedSlots:{customRender:'user'}
                },
                {
                    title:'Actions',
                    key:'action',
                    slots:{title:'Actions'},
                    scopedSlots:{customRender:'action'}
                }
            ],
            newField:{
                name:'',
                type:'',
                multipleAnswer:false,
                defaultAnswer:'',
                options:['', ''],
                id:0,
                date:0
            },
        }
    },
    methods:{
        deletePremium(obj) {
            let self = this
            if (this.$p < 20) return this.$message.error('You do not have permission to delete unit premium')
            this.$confirm({
                title: "Delete Unit Premium",
                content: h => <div>Do you want to delete this Unit Premium?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk() {
                    let premiums = JSON.parse(JSON.stringify(self.premiums))
                    let index = premiums.findIndex(x => x.id == obj.id)
                    premiums.splice(index, 1)

                    let settings = JSON.parse(JSON.stringify(self.$store.state.condoUnits.allSettings.app.options))

                    settings.premiums = premiums
                    self.$api.post(`/settings/:instance/condounits`, {options:settings}).then(({data}) => {
                        self.$store.commit('UPDATE_SETTINGS', data)
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        editPremium(obj) {
            this.$store.commit('EDIT_MODAL', {type:'premium', obj:obj})
        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        req:msg=>({required:true,message:msg}),
        addPremium(){
            this.$store.commit('OPEN_MODAL', 'premium')
        },
        onClose() {
            this.fieldDrawer.visible = false
            this.fieldDrawer.type = 'add'
            this.newField = {
                name:'',
                type:'',
                multipleAnswer:false,
                defaultAnswer:'',
                options:['','']
            }
            this.$refs.newField.resetFields();
        }
    },
}
</script>

<style>
.cancel-button.ant-btn {
    border-color:#ECE9F1 !important;
    background-color:#ECE9F1;
    color:#3F3356
}
</style>
