<template>
    <div class="dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
        <a-card title="Condo Address">
            <a-form-model class="mt-3" :model="address" ref="newSetup">
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-model-item
                            label="Street Number"
                            prop="streetNumber"
                        >
                            <a-input
                                :min="0"
                                type="number"
                                v-model="address.streetNumber"
                                placeholder="Street Number"
								size="large"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="Street Name" prop="street">
                            <a-auto-complete
                                v-model="address.street"
                                placeholder="Street Name"
								size="large"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item
                            :label="
                                country === 'CA' ? 'Postal Code' : 'Zip Code'
                            "
                            prop="postal"
                        >
                            <a-input
                                v-model="address.postal"
                                :placeholder="
                                    country === 'CA'
                                        ? 'Postal Code'
                                        : 'Zip Code'
                                "
								size="large"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-model-item label="City" prop="city">
                            <a-input
                                v-model="address.city"
                                placeholder="City"
								size="large"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item
                            :label="country === 'CA' ? 'Province' : 'State'"
                            prop="region"
                        >
                            <a-input
                                v-model="address.region"
                                :placeholder="
                                    country === 'CA' ? 'Province' : 'State'
                                "
								size="large"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>

            <a-button
                class="pull-right"
                size="large"
                type="primary"
                @click="save"
                :loading="loading"
                >SAVE</a-button
            >
        </a-card>
    </div>
</template>

<script>
export default {
    props: {
        value: Number,
    },
    data: () => ({
        loading: false,
        address: {
            streetNumber: "",
            street: "",
            postal: "",
            city: "",
            region: "",
        },
    }),
    computed: {
        instance() {
            return this.$store.state.instance || {};
        },
        country() {
            const country = (this.instance.country || "")?.toLowerCase().trim();
            return country === "canada" || country === "ca" ? "CA" : "USA";
        },
        condoAddress() {
            const address = this.$store.state.condoUnits?.allSettings?.app?.options?.address;
            return address || {
                streetNumber: "",
                street: "",
                postal: "",
                city: "",
                region: "",
            };
        },
    },
    watch: {
        condoAddress(val) {
            this.address = val;
        },
    },
    created() {
        this.address = this.condoAddress;
    },

    methods: {
        save() {
            this.loading = true;
            this.$api
                .put(`/settings/:instance/condounits`, {
                    options: { address: this.address },
                })
                .then(({data}) => {
                	this.$store.commit("UPDATE_SETTINGS", data);
                    this.$message.success(
                        "Condo address updated successfully."
                    );
                })
                .catch((err) => {
                    console.error("ERROR in adding address details", err);
                    this.$message.success(
                        "Error occurred while updating condo address. Please try again!"
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style>
</style>
